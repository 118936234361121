@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Pretendard";
  src: url('./font/Pretendard-Medium.woff')
} */
body {
    font-family: "Pretendard Variable", "Pretendard";
    background-color: #f6f7fa;
    -ms-overflow-style: none;
}

* {
    overscroll-behavior-y: none;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

::-webkit-scrollbar {
    display: none;
}

#root {
    @apply relative z-0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

select {
    /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
    @apply bg-Gray00 MBody14;
}

input:focus {
    outline: none;
}
select:focus {
    outline: none;
}

.Toastify__toast {
    max-width: 312px !important;
    width: 100% !important;
    margin: 0px auto !important;
}

.Toastify__toast-container {
    bottom: 80px !important;
}

.Toastify__toast {
    @apply rounded-full bg-Gray07 text-Gray00 MBody14 !important;
}

.Toastify__toast-icon {
    @apply w-7 h-7 !important;
}

.youtubeRounded {
    @apply !w-full;
}
.dropdownWrap {
    @apply max-w-[476px] py-2.5 px-3
  /* hover:bg-Blue-50; */;
}
.dropdownLi {
    @apply max-w-[476px] w-full
  /* Body4 text-Gray-80; */;
}

.dropdown li {
    @apply p-2 hover:bg-Gray01 rounded-[10px];
}
.optionSelectBoxDiv div {
    @apply w-full hover:bg-Gray01 rounded-[10px] p-2 cursor-pointer;
}

.rolling-container {
    height: 18px;
    background-color: black;
    overflow: hidden;
    min-width: 280px;
    max-width: 400px;
    color: white;
    display: flex;
    align-items: center;
}

.rolling-text {
    display: flex;
    width: max-content;
    animation: rolling 25s linear infinite;
}

.rolling-text > div {
    padding: 0 16px;
    white-space: nowrap;
}

@keyframes rolling {
    0% {
        transform: translateX(20%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes bottom {
    0% {
        bottom: -100%;
    }

    100% {
        bottom: 0;
    }
}

.animate-bottom {
    animation: bottom 0.2s ease-in-out;
}

.rangeBar {
    position: absolute;
    top: -8px;
    width: 100%;
    -webkit-appearance: none;
    background: none;
    z-index: 100000;

    &::-webkit-slider-thumb {
        height: 16px;
        width: 16px;
        border: 1px solid #4383ff;
        border-radius: 50%;
        -webkit-appearance: none;
    }

    pointer-events: none;

    &::-webkit-slider-thumb {
        pointer-events: auto;
    }
}

input[type="date"] {
    @apply border RBody16 border-Gray03 rounded-md !py-3 !w-full !px-4;
}

input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type="date"]::-webkit-date-and-time-value {
    text-align: left;
}

input[type="date"]::before {
    content: attr(date-placeholder);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @apply text-Gray03 RBody16;
}

input[type="date"]:valid::before {
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
}
/* 
input[type="date"]::-webkit-datetime-edit-text {
    visibility: hidden;
    -webkit-appearance: none;
}
input[type="date"]::-webkit-datetime-edit-month-field {
    visibility: hidden;
    -webkit-appearance: none;
}
input[type="date"]::-webkit-datetime-edit-day-field {
    visibility: hidden;
    -webkit-appearance: none;
}
input[type="date"]::-webkit-datetime-edit-year-field {
    visibility: hidden;
    -webkit-appearance: none;
} */

input[type="date"]:valid::-webkit-datetime-edit-text,
input[type="date"]:valid::-webkit-datetime-edit-year-field,
input[type="date"]:valid::-webkit-datetime-edit-month-field,
input[type="date"]:valid::-webkit-datetime-edit-day-field {
    visibility: visible;
}

.skeleton {
    background: linear-gradient(
        90deg,
        rgba(165, 165, 165, 0.1) 25%,
        rgba(165, 165, 165, 0.3) 50%,
        rgba(165, 165, 165, 0.1) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.skeleton-container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.swiper {
    @apply !mx-0;
}

@layer components {
    .nonDragable {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .RCaption10 {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
    }
    .RCaption12 {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
    }
    .RCaption14 {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
    .RBody14 {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
    .RBody16 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .RBody18 {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
    }
    .RBody20 {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }
    .RHead24 {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
    }
    .RHead28 {
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
    }
    .RHead32 {
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
    }
    .RHead36 {
        font-size: 36px;
        font-weight: 400;
        line-height: 54px;
    }
    .RHead48 {
        font-size: 48px;
        font-weight: 400;
        line-height: 72px;
    }

    .MCaption10 {
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
    }
    .MCaption12 {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
    }
    .MCaption14 {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }
    .MBody14 {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }
    .MBody16 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .MBody18 {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
    }
    .MBody20 {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
    }
    .MHead24 {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
    }
    .MHead28 {
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
    }
    .MHead32 {
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
    }
    .MHead36 {
        font-size: 36px;
        font-weight: 500;
        line-height: 54px;
    }
    .MHead48 {
        font-size: 48px;
        font-weight: 500;
        line-height: 72px;
    }

    .BCaption10 {
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
    }
    .BCaption12 {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
    }
    .BCaption14 {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }
    .BBody14 {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }
    .BBody16 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }
    .BBody18 {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
    }
    .BBody20 {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
    }
    .BHead24 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
    }
    .BHead28 {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
    }
    .BHead32 {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
    }
    .BHead36 {
        font-size: 36px;
        font-weight: 700;
        line-height: 54px;
    }
    .BHead48 {
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
    }

    .textHidden {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
